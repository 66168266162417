'use client';

import { textVariant } from '@/src/lib/motion';
import MotionWrapper from '@/src/lib/motionWrapper';
import { cn } from '@/src/lib/utils';
import { m } from 'framer-motion';

export function TypographyHeroLead({ children, className }) {
	return (
		<MotionWrapper>
			<m.p
				variants={textVariant(0.1)}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
				className={cn(
					'font-regular mx-auto max-w-xl text-pretty pb-8 text-md text-black lg:text-lg',
					className
				)}
			>
				{children}
			</m.p>
		</MotionWrapper>
	);
}
