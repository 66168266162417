import { cn } from '@/src/lib/utils';

const ArrowDownIcon = ({ className }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={cn('size-6 text-white', className)}
			viewBox="0 0 24 24"
			fill="currentColor"
		>
			<path d="M13.0001 16.1716L18.3641 10.8076L19.7783 12.2218L12.0001 20L4.22192 12.2218L5.63614 10.8076L11.0001 16.1716V4H13.0001V16.1716Z"></path>
		</svg>
	);
};

export default ArrowDownIcon;
