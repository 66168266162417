'use client';

import MotionWrapper from '@/src/lib/motionWrapper';
import { cn } from '@/src/lib/utils';
import { m } from 'framer-motion';
import { textVariant } from '../../lib/motion';

export function TypographyHeroH1({ children, className }) {
	return (
		<MotionWrapper>
			<m.h1
				variants={textVariant(0.1)}
				className={cn(
					'scroll-m-20 text-balance text-4xl font-black leading-none text-white md:text-5xl',
					className
				)}
			>
				{children}
			</m.h1>
		</MotionWrapper>
	);
}
