import Image from 'next/image';
import Link from 'next/link';
import { Button } from '../ui/button';
import plusIcon from '/public/vectors/plus-circle-icon.svg';

const FreeTrialClassLink = () => {
	return (
		<Link href="/classes">
			<Button
				variant="default"
				className="bg-yellow-secondary font-recursive font-medium tracking-tight text-black transition-all duration-300 hover:bg-white hover:font-semibold"
			>
				<Image
					src={plusIcon}
					alt="Add icon"
					aria-hidden="true"
					className="mr-1 size-4 shrink-0"
				/>
				Book Free Trial Class
			</Button>
		</Link>
	);
};

export default FreeTrialClassLink;
