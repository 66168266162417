import { cn } from '@/src/lib/utils';

export function TypographyCardTitle({ children, className }) {
	return (
		<p
			className={cn(
				'mx-auto max-w-xl text-pretty text-md font-extrabold text-black',
				className
			)}
		>
			{children}
		</p>
	);
}
