'use client';

import { m } from 'framer-motion';
import Image from 'next/image';

import { imageCollectionVariantsAlt } from '@/src/lib/motion';

import MotionWrapper from '@/src/lib/motionWrapper';
import groundfight from '../../../public/about/portrait/men-ground-fight.webp';
import wrestling from '../../../public/about/portrait/men-wrestling.webp';
import womanbagwork from '../../../public/about/portrait/woman-bagwork.webp';
import womenbagwork from '../../../public/about/portrait/women-bagwork.webp';

const AboutHomeImages = () => {
	return (
		<MotionWrapper>
			<m.div
				variants={imageCollectionVariantsAlt}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0 }}
				className="col-span-1 md:col-span-2"
			>
				<div className="hidden grid-cols-1 gap-4 sm:grid">
					<div className="grid grid-cols-1 md:grid-cols-2">
						<m.span variants={imageCollectionVariantsAlt}>
							<Image
								className="w-full -rotate-1 rounded-lg transition-all duration-300 ease-in-out hover:scale-[1.02] md:mt-4"
								src={wrestling}
								width={592}
								height={822}
								sizes="25vw"
								alt="Men wrestling"
								placeholder="blur"
							/>
						</m.span>
						<m.span variants={imageCollectionVariantsAlt}>
							<Image
								className="-ml-1 -mt-2 w-full -rotate-2 rounded-lg transition-all duration-300 ease-in-out hover:scale-[1.02] md:mt-0 md:rotate-2"
								src={womenbagwork}
								width={592}
								height={822}
								sizes="25vw"
								alt="Women training on punch bags"
								placeholder="blur"
							/>
						</m.span>
					</div>
					<div className="-mt-3 grid grid-cols-1 md:mt-0 md:grid-cols-2">
						<m.span variants={imageCollectionVariantsAlt}>
							<Image
								className="-mt-4 w-full rotate-2 rounded-lg transition-all duration-300 ease-in-out hover:scale-[1.02] md:-mt-3 md:ml-2"
								src={womanbagwork}
								width={592}
								height={822}
								sizes="25vw"
								alt="Woman training on a punch bag"
								placeholder="blur"
							/>
						</m.span>
						<m.span variants={imageCollectionVariantsAlt}>
							<Image
								className="-mt-2 w-full -rotate-2 rounded-lg transition-all duration-300 ease-in-out hover:scale-[1.02] md:-ml-2 md:-mt-8"
								src={groundfight}
								width={592}
								height={822}
								sizes="25vw"
								alt="Men ground-fighting"
								placeholder="blur"
							/>
						</m.span>
					</div>
				</div>
			</m.div>
		</MotionWrapper>
	);
};

export default AboutHomeImages;
