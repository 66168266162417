import { ReactNode } from 'react';

import Image from 'next/image';
import starWhiteIcon from '/public/vectors/star-white-icon.svg';
import starYellowIcon from '/public/vectors/star-yellow-icon.svg';

interface StarsRowProps {
	count: number;
	size?: number;
	color?: string;
}

const StarsRow = (props: StarsRowProps): ReactNode => {
	const { count, size, color } = props;

	let _starSize = size || 4;
	let starColor = color || 'white';
	let stars;

	const starsCount = count != 0 ? count : 5;
	const starsArr: number[] = new Array(starsCount).fill(0);

	if (starColor === 'white') {
		stars = starsArr.map((_star: number, index: number) => (
			<Image
				key={index}
				src={starWhiteIcon}
				alt="Star icon"
				aria-hidden="true"
				className="size-4 shrink-0"
				priority={true}
			/>
		));
	}

	if (starColor === 'yellow') {
		stars = starsArr.map((_star: number, index: number) => (
			<Image
				key={index}
				src={starYellowIcon}
				alt="Star icon"
				aria-hidden="true"
				className="size-4 shrink-0"
			/>
		));
	}

	return <>{stars}</>;
};

export default StarsRow;
