import { fadeIn } from '@/src/lib/motion';
import { cn } from '@/src/lib/utils';
import { m } from 'framer-motion';

import MotionWrapper from '@/src/lib/motionWrapper';
import StarsRow from '../icons/StarsRow';

const HeroReview = ({ review, index }) => {
	return (
		<MotionWrapper>
			<m.div
				variants={fadeIn('up', 'spring', index * 0.1, 1)}
				className={cn(
					'mx-auto grid-cols-1 gap-8 md:mb-4',
					index > 0 ? 'hidden h-0 sm:grid sm:h-fit' : 'grid'
				)}
			>
				<div className="flex flex-col items-center gap-y-2">
					<div className="flex items-center justify-center gap-2">
						<StarsRow count={5} />
					</div>
					<div className="casual-100 cursive-off text-sm text-white/95">
						&ldquo;{review}&rdquo;
					</div>
				</div>
			</m.div>
		</MotionWrapper>
	);
};

export default HeroReview;
