'use client';

import { textVariant } from '@/src/lib/motion';
import MotionWrapper from '@/src/lib/motionWrapper';
import { m } from 'framer-motion';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import { Button } from '../ui/button';
import FreeTrialClassLink from './FreeTrialClassLink';

const scrolltoHash = function (element_id: string) {
	const element = document.getElementById(element_id);
	element?.scrollIntoView({
		behavior: 'smooth',
		block: 'start',
		inline: 'center',
	});
};

const HeroButtons = () => {
	return (
		<MotionWrapper>
			<m.div
				variants={textVariant(0.2)}
				className="flex items-center justify-center gap-3"
			>
				<FreeTrialClassLink />
				<Button
					className="group hidden bg-white/20 font-recursive font-medium tracking-tight backdrop-blur transition-colors duration-300 md:inline-flex"
					variant="outline"
					onClick={() => scrolltoHash('syllabus')}
				>
					<ArrowDownIcon className="pr-2 transition-colors duration-300 group-hover:text-black" />
					View our syllabus
				</Button>
			</m.div>
		</MotionWrapper>
	);
};

export default HeroButtons;
