'use client';

import { tickerVariants } from '@/src/lib/motion';
import MotionWrapper from '@/src/lib/motionWrapper';
import { m } from 'framer-motion';

const Ticker = () => {
	return (
		<MotionWrapper>
			<m.div
				variants={tickerVariants}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
				className="group flex flex-row gap-2 overflow-hidden bg-[#141414] py-2 font-inter"
				aria-hidden="true"
			>
				<div
					className="flex animate-loop-scroll gap-2 hover:cursor-auto group-hover:paused"
					aria-hidden="true"
				>
					<NextClass />
				</div>
				<div
					className="flex animate-loop-scroll gap-2 hover:cursor-auto group-hover:paused"
					aria-hidden="true"
				>
					<NextClass />
				</div>
				<div
					className="flex animate-loop-scroll gap-2 hover:cursor-auto group-hover:paused"
					aria-hidden="true"
				>
					<NextClass />
				</div>
			</m.div>
		</MotionWrapper>
	);
};

export default Ticker;

const NextClass = () => {
	const items: number[] = new Array(6).fill(0);

	return items.map((_item, index) => (
		<div
			className="w-full whitespace-nowrap bg-[#141414] text-sm uppercase text-yellow-secondary"
			aria-hidden="true"
			key={index}
		>
			<p className="flex flex-row items-center gap-2">
				<span
					aria-hidden="true"
					className="size-2 rounded-full bg-yellow-tertiary"
				/>
				<span>
					<span className="font-normal">NEXT CLASS:&nbsp;</span>
					<span className="font-bold">SUNDAY 10:00–12:00</span>
				</span>
			</p>
		</div>
	));
};
