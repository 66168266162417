'use client';

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
	AccordionTriggerButton,
} from '@/src/components/ui/accordion';
import { textVariant } from '@/src/lib/motion';
import MotionWrapper from '@/src/lib/motionWrapper';
import { cn } from '@/src/lib/utils';
import { faqsData } from '@/src/shared/data';
import { m } from 'framer-motion';
import Image from 'next/image';
import { TypographyCardTitle } from '../typography/TypographyCardTitle';
import { TypographyParagraph } from '../typography/TypographyParagraph';
import questionIcon from '/public/vectors/question-mark-icon.svg';

export default function FAQsContent() {
	return (
		<>
			<MotionWrapper>
				{/* Accordion */}
				<div className="block text-left md:hidden">
					<Accordion type="single" collapsible className="w-full">
						{faqsData.map((faq, index) => (
							<m.div
								variants={textVariant(0.2)}
								initial="hidden"
								whileInView="show"
								viewport={{ once: true, amount: 0 }}
								key={`faq-${index}`}
							>
								<AccordionItem
									value={String(index)}
									className={cn(
										'border-b border-black',
										index === 0 ? 'border-t' : 'border-t-0'
									)}
								>
									<AccordionTrigger className="">
										<div className="flex items-center">
											<Image
												src={questionIcon}
												alt="Question mark icon"
												aria-hidden="true"
												className="mr-3 size-6 shrink-0 sm:size-8"
											/>
											<TypographyCardTitle className="casual-100 cursive-off">
												{faq.question}
											</TypographyCardTitle>
										</div>
										<AccordionTriggerButton
											buttonClassName="hover:border-black/30 group-hover:bg-black/10"
											iconColor="black"
										/>
									</AccordionTrigger>
									<AccordionContent>
										{faq.answer.map((paragraph, index) => {
											return (
												<TypographyParagraph
													key={`answer-${index}`}
													className="mb-4"
												>
													{paragraph}
												</TypographyParagraph>
											);
										})}
									</AccordionContent>
								</AccordionItem>
							</m.div>
						))}
					</Accordion>
				</div>
				{/* 3 Column Masonry Grid */}
				<div className="hidden gap-8 space-y-10 text-left md:block md:columns-2 lg:columns-3">
					{faqsData.map((faq, index) => (
						<m.div
							variants={textVariant(0.2)}
							initial="hidden"
							whileInView="show"
							viewport={{ once: true, amount: 0.25 }}
							className="flex"
							key={index}
						>
							<Image
								src={questionIcon}
								alt="Question mark icon"
								aria-hidden="true"
								className="mr-4 size-6 shrink-0 sm:size-8"
							/>
							<div
								className="flex break-inside-avoid-column flex-col gap-y-4 text-base leading-normal text-black"
								key={`faq-${index}`}
							>
								<TypographyCardTitle className="casual-100 cursive-off mx-0 text-left">
									{faq.question}
								</TypographyCardTitle>

								{faq.answer.map((paragraph, index) => {
									return (
										<p
											className="text-pretty"
											key={`answer-${index}`}
										>
											{paragraph}
										</p>
									);
								})}
							</div>
						</m.div>
					))}
				</div>
			</MotionWrapper>
		</>
	);
}
