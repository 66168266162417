'use client';

import { staggerContainer } from '@/src/lib/motion';
import { heroReviewsData } from '@/src/shared/data';

import MotionWrapper from '@/src/lib/motionWrapper';
import { m } from 'framer-motion';
import HeroReview from './HeroReview';

const HeroReviews = () => {
	return (
		<MotionWrapper>
			<m.div
				variants={staggerContainer()}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
				className="casual-100 cursive-off mx-auto mb-4 grid grid-cols-1 gap-8 sm:grid-cols-3"
			>
				{heroReviewsData.map((review, index) => {
					return (
						<HeroReview
							review={review}
							key={`hero-review-${index}`}
							index={index}
						/>
					);
				})}
			</m.div>
		</MotionWrapper>
	);
};

export default HeroReviews;
