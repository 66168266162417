'use client';

import MotionWrapper from '@/src/lib/motionWrapper';
import { cn } from '@/src/lib/utils';
import { reviewsData } from '@/src/shared/data';
import { m } from 'framer-motion';
import Image from 'next/image';
import { staggerContainer, textVariant } from '../../lib/motion';
import StarsRow from '../icons/StarsRow';
import { TypographyCardTitle } from '../typography/TypographyCardTitle';
import { TypographyParagraph } from '../typography/TypographyParagraph';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
	AccordionTriggerButton,
} from '../ui/accordion';
import risingSunIcon from '/public/vectors/rising-sun-white-transparent-icon.svg';

export default function ReviewsContent() {
	return (
		<>
			<MotionWrapper>
				<div className="hidden border-0 px-4 text-left sm:block sm:py-8 lg:border-t lg:border-black lg:px-6 lg:py-8">
					<m.div
						variants={staggerContainer()}
						initial="hidden"
						whileInView="show"
						viewport={{ once: true, amount: 0 }}
						className="-mt-8 sm:-mx-4 sm:columns-2 lg:columns-3"
					>
						{reviewsData.map((review, index) => (
							<m.div
								variants={textVariant(0.2)}
								initial="hidden"
								whileInView="show"
								viewport={{ once: true, amount: 0.25 }}
								key={review.author.name + index}
								className="pt-8 sm:inline-block sm:w-full sm:px-4"
							>
								<figure className="rounded-md bg-black/30 p-6">
									<blockquote className="text-sm">
										<div className="mb-4 flex justify-start gap-2">
											<StarsRow
												count={5}
												size={4}
												color="yellow"
											/>
										</div>
										<TypographyCardTitle
											className="casual-100 cursive-off
                                    text-white"
										>
											&ldquo;{review.title}&rdquo;
										</TypographyCardTitle>
										<TypographyParagraph className="casual-30 cursive-off my-4 text-gray-400">
											&ldquo;{review.body}&rdquo;
										</TypographyParagraph>
									</blockquote>
									<figcaption className="flex items-center space-x-3">
										<Image
											src={risingSunIcon}
											alt="Rising sun icon"
											aria-hidden="true"
											className="size-6"
										/>
										<div className="space-y-0.5 text-base font-medium text-white">
											<div>{review.author.name}</div>
										</div>
									</figcaption>
								</figure>
							</m.div>
						))}
					</m.div>
				</div>
				<div className="block sm:hidden">
					{/* Accordion */}
					<div className="block text-left md:hidden">
						<Accordion type="single" collapsible className="w-full">
							{reviewsData.map((review, index) => (
								<m.div
									variants={textVariant(0.3)}
									initial="hidden"
									whileInView="show"
									viewport={{ once: true, amount: 0.25 }}
									key={`review-${index}`}
								>
									<AccordionItem
										value={String(index)}
										className={cn(
											'border-divider bg-black/30 px-4 py-2',
											itemFormatter(index)
										)}
									>
										<AccordionTrigger className="">
											<div className="flex items-center">
												<TypographyCardTitle className="casual-100 cursive-off ml-2 text-white">
													&ldquo;{review.title}&rdquo;
												</TypographyCardTitle>
											</div>
											<AccordionTriggerButton
												buttonClassName="hover:border-white/30 group-hover:bg-white/10"
												iconColor="white"
											/>
										</AccordionTrigger>
										<AccordionContent className="pl-2 xl:pl-12">
											<div className="flex w-full flex-col gap-x-8 gap-y-6 md:flex-row">
												<TypographyParagraph className="casual-30 cursive-off text-pretty pr-3 text-left text-gray-400 md:w-2/5">
													&ldquo;{review.body}&rdquo;
												</TypographyParagraph>
												<div className="flex items-center justify-between gap-2">
													<div className="flex items-center space-x-3">
														<Image
															src={risingSunIcon}
															alt=""
															aria-hidden="true"
															className="size-6"
														/>
														<div className="space-y-0.5 text-base font-medium text-white">
															{review.author.name}
														</div>
													</div>
													<div className="flex justify-start gap-2 pr-3">
														<StarsRow
															count={5}
															size={4}
															color="yellow-primary"
														/>
													</div>
												</div>
											</div>
										</AccordionContent>
									</AccordionItem>
								</m.div>
							))}
						</Accordion>
					</div>
				</div>
			</MotionWrapper>
		</>
	);
}

const itemFormatter = (index: number): string => {
	if (index === 0) {
		return 'border-b rounded-tl-md rounded-tr-md';
	} else if (index === reviewsData.length - 1) {
		return 'border-b-0 rounded-bl-md rounded-br-md';
	} else {
		return 'border-b';
	}
};
