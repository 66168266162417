'use client';

import { textVariant } from '@/src/lib/motion';
import MotionWrapper from '@/src/lib/motionWrapper';
import { m } from 'framer-motion';
import Image from 'next/image';
import beltIcon from '/public/vectors/belt-white-icon.svg';
import maleFemaleIcon from '/public/vectors/male-female-icon.svg';
import adultsIcon from '/public/vectors/teens-adults-icon.svg';

const HeroIcons = () => {
	return (
		<MotionWrapper>
			<m.div
				variants={textVariant(0.3)}
				className="mx-auto grid max-w-md grid-cols-2 justify-between gap-4 pb-8 xs:grid-cols-3 sm:gap-8"
			>
				<div className="flex items-center gap-2 sm:gap-3">
					<Image
						src={maleFemaleIcon}
						alt="Male/Female icon"
						aria-hidden="true"
						className="w-auto shrink-0 md:h-[48px] lg:h-[58px]"
						priority={true}
					/>
					<p className="flex flex-col items-start gap-0 text-xs sm:text-sm md:text-base">
						<span>Mixed</span>
						<span>Classes</span>
					</p>
				</div>
				<div className="flex items-center gap-2 sm:gap-3">
					<Image
						src={adultsIcon}
						alt="Age icon"
						aria-hidden="true"
						className="h-[40px] w-auto shrink-0 md:h-[48px] lg:h-[58px]"
						priority={true}
					/>
					<p className="flex flex-col items-start gap-0 text-xs sm:text-sm md:text-base ">
						<span>Teens</span>
						<span>& Adults</span>
					</p>
				</div>
				<div className="hidden items-center gap-2 xs:flex xs:gap-3">
					<Image
						src={beltIcon}
						alt="Belt icon"
						aria-hidden="true"
						className="h-auto w-[70px] shrink-0 md:w-[80px] lg:w-[90px] xl:w-[100px]"
						priority={true}
					/>
					<p className="flex flex-col items-start gap-0 text-xs md:text-base ">
						<span>Graded</span>
						<span>Syllabus</span>
					</p>
				</div>
			</m.div>
		</MotionWrapper>
	);
};

export default HeroIcons;
