'use client';

import MotionWrapper from '@/src/lib/motionWrapper';
import { m } from 'framer-motion';
import Image from 'next/image';
import { staggerContainer } from '../../lib/motion';
import { TypographyHeroH1 } from '../typography/TypographyHeroH1';
import { TypographyHeroLead } from '../typography/TypographyHeroLead';
import HeroButtons from './HeroButtons';
import HeroIcons from './HeroIcons';
import HeroReviews from './HeroReviews';
import underlineIcon from '/public/vectors/underline-icon.svg';

const HeroContent = () => {
	return (
		<MotionWrapper>
			<m.div
				variants={staggerContainer()}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
				className="flex flex-col gap-6 sm:gap-8"
			>
				<HeroReviews />

				<TypographyHeroH1 className="mx-auto max-w-xl text-center md:max-w-2xl md:text-5xl lg:text-6xl">
					Mixed Martial Arts Classes in Ipswich
				</TypographyHeroH1>

				<TypographyHeroLead className="mx-auto -mt-1 max-w-md px-2 pb-0 text-white/95 sm:px-0 md:max-w-xl md:text-xl">
					Become that{' '}
					<span className="relative whitespace-nowrap text-white">
						<Image
							src={underlineIcon}
							alt="Brush stroke"
							aria-hidden="true"
							className="absolute -bottom-3 left-1 h-[90%] w-full -rotate-2 scale-x-[1.8]"
						/>
						rare person
					</span>{' '}
					who can handle themselves in any threatening situation
				</TypographyHeroLead>

				<HeroButtons />
				<HeroIcons />
			</m.div>
		</MotionWrapper>
	);
};

export default HeroContent;
